.bestSellers .bestSellerSplash {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  position: relative;
}
@media (max-width: 991.98px) {
.bestSellers .bestSellerSplash {
    min-height: 350px;
}
}
.bestSellers .bestSellerBlackBox {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  max-height: calc(100% - 2rem);
  width: 75%;
  max-width: 350px;
  background: rgba(41, 46, 68, 0.8);
  left: 60px;
  padding: 30px;
}
.bestSellers .bestSellerBlackBox .boxTitle {
  color: #fff;
}
.bestSellers .bestSellerBlackBox .boxText {
  color: #fff;
  margin-bottom: 0;
}
@media (max-width: 575.98px) {
.bestSellers .bestSellerBlackBox {
    left: 0;
    right: 0;
    margin: 0 auto;
}
}
.bestSellers .slick {
  position: relative;
}
.bestSellers .slick #slickBestsellerPrev,
.bestSellers .slick #slickBestSellersNext {
  position: absolute;
  top: 50%;
  width: 32px;
  height: 32px;
  text-align: center;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.9);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
}
.bestSellers .slick #slickBestsellerPrev#slickBestsellerPrev,
.bestSellers .slick #slickBestSellersNext#slickBestsellerPrev {
  left: 0;
}
.bestSellers .slick #slickBestsellerPrev#slickBestSellersNext,
.bestSellers .slick #slickBestSellersNext#slickBestSellersNext {
  right: 0;
}
.bestSellers .slick #slickBestsellerPrev#slickBestSellersNext svg,
.bestSellers .slick #slickBestSellersNext#slickBestSellersNext svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
@media (max-width: 575.98px) {
.bestSellers .slick #slickBestsellerPrev,
.bestSellers .slick #slickBestSellersNext {
    -webkit-transform: none !important;
            transform: none !important;
}
}
@media (min-width: 768px) {
.bestSellers .slick #slickBestsellerPrev,
.bestSellers .slick #slickBestSellersNext {
    width: 38px;
    height: 38px;
}
}
.bestSellers .slick .slick-slider {
  position: relative;
  margin: 0 30px;
}
.bestSellers .slick .slick-slide {
  width: 280px;
  padding: 0 1rem 0;
}
.bestSellers .slick .slick-slide img {
  max-width: 100%;
  margin-bottom: 1rem;
}
.bestSellers .slick-slide {
  cursor: pointer;
}
.bestSellers .boxsetInfo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.bestSellers .boxsetInfo > svg {
  width: 40px;
  margin-right: 1rem;
}
.bestSellers .boxsetText {
  color: #333333;
  font-size: 12px;
}
.bestSellers .boxsetText .boxsetTitle {
  display: block;
  font-weight: 600;
  font-size: 19px;
  margin-top: 12px;
}
.bestSellers .boxsetPrice {
  /*
  display:flex;
  align-items:center;
  text-align:left;
  margin-top:4px;
  svg
  {
      margin-right:8px;
  }
  *
  {
      font-size: 13px;
      font-weight: bold;
  }
  */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 15px 0;
  font-size: 16px;
  font-weight: bold;
  color: #f07b5d;
}
.bestSellers .boxsetPrice svg {
  margin-right: 4px;
}
.bestSellers .boxsetPrice svg path {
  stroke: #f07b5d;
  fill: none;
}
.bestSellers button {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
}
.bestSellers .bestSellersCta {
  margin-top: 1rem;
}
.bestSellers .bestSellersCta .btn {
  -webkit-box-shadow: none;
          box-shadow: none;
}