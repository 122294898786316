.boxsetCatalog .scopriDisponibilita {
  font-weight: bold;
  font-size: 0.875rem;
}
.boxsetCatalog .boxsetCatalogTrendingDestinations {
  font-size: 13px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 1rem;
}
.boxsetCatalog .boxsetCatalogTrendingDestinations p {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-bottom: 0;
}
.boxsetCatalog .boxsetCatalogTrendingDestinations svg {
  min-width: 11px;
  margin-right: 6px;
  margin-top: 3px;
}
.boxsetCatalog .boxsetCatalogTrendingDestinations svg * {
  fill: #fff;
  stroke: #333333;
}
.boxsetCatalog .trendingDestionation {
  color: #0077CA;
  text-decoration: underline;
  position: relative;
  cursor: pointer;
}
.boxsetCatalog .trendingDestionation:hover {
  text-decoration: underline;
}
@media (max-width: 1199.98px) {
.boxsetCatalog .trendingDestionation:not(:last-child):after {
    content: ", ";
    text-decoration: none;
    margin-right: 3px;
    display: inline-block;
}
}
@media (min-width: 1200px) {
.boxsetCatalog .trendingDestionation:not(:last-child) {
    padding-right: 8px;
    margin-right: 8px;
    white-space: nowrap;
}
.boxsetCatalog .trendingDestionation:not(:last-child):after {
    content: "";
    position: absolute;
    top: 60%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    right: 0;
    width: 1px;
    height: 14px;
    background: #0077CA;
}
}
.modal-header {
  margin-bottom: 0 !important;
}
.boxsetCatalogModal #searchBar {
  padding: 15px 30px;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.boxsetCatalogModal .userBox,
.boxsetCatalogModal .breadcrumbs,
.boxsetCatalogModal #search-form-filters {
  display: none !important;
}
.boxsetCatalogModal #search-form-buttons {
  padding-right: 0 !important;
}
.boxsetCatalogModal #search-form {
  padding-right: 0 !important;
}
.boxsetCatalogModal #search-form form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.boxsetCatalogModal #search-form form .search-form-item {
  -webkit-box-flex: 100% !important;
      -ms-flex: 100% !important;
          flex: 100% !important;
  margin-right: 0 !important;
  padding-right: 0 !important;
  min-width: 100% !important;
}
.boxsetCatalogModal #search-form form .search-form-item:not(#search-form-buttons) {
  margin-bottom: 1rem !important;
}
.boxsetCatalogModal #search-form form .search-form-item#search-form-buttons {
  margin-top: 1.25rem !important;
}
.boxsetCatalogModal #search-form #search-form-datepicker,
.boxsetCatalogModal #search-form .datepicker-fields {
  position: static !important;
}
.boxsetCatalogModal #search-form .asd__wrapper--datepicker-open {
  top: 50% !important;
  left: 50% !important;
  -webkit-transform: translateY(-50%) translateX(-50%) !important;
          transform: translateY(-50%) translateX(-50%) !important;
}