#searchBoxset {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-top: 10px;
  padding-bottom: 20px;
}
@media (min-width: 992px) {
#searchBoxset {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
}
}
#searchBoxset .boxsetFormItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
#searchBoxset .boxsetFormItem:not(:last-child) {
  margin-bottom: 20px;
}
@media (min-width: 992px) {
#searchBoxset .boxsetFormItem:not(:last-child) {
    margin-bottom: 0;
    margin-right: 30px;
}
}
#searchBoxset #search-boxset-welfare {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#searchBoxset label {
  font-size: 14px;
  margin-bottom: 0;
}
#searchBoxset .boxsetFormItem:not(#search-boxset-welfare) label {
  font-weight: 600;
}
#searchBoxset select {
  font-size: 14px;
}
@media (min-width: 992px) {
#searchBoxset #search-boxset-buttons {
    max-width: 200px;
}
}
#searchBoxset #search-boxset-welfare .input-with-icon {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#searchBoxset #search-boxset-welfare .input-with-icon > label {
  padding-right: 0.8rem;
  line-height: 120%;
  text-transform: none;
}
@media (min-width: 768px) {
#searchBoxset #search-boxset-welfare {
    margin-top: 24px;
}
}
@media (max-width: 1199.98px) {
#searchBoxset .custom-switch {
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
}
}
@media (min-width: 768px) and (max-width: 991.98px) {
#searchBoxset {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
#searchBoxset .boxsetFormItem {
    min-width: calc(50% - 1rem);
    max-width: calc(50% - 1rem);
    margin: auto;
}
#searchBoxset .boxsetFormItem:nth-child(1), #searchBoxset .boxsetFormItem:nth-child(3) {
    margin-right: 0.5rem;
}
#searchBoxset .boxsetFormItem:nth-child(2), #searchBoxset .boxsetFormItem:nth-child(4) {
    margin-left: 0.5rem;
}
}